import React from 'react';
import PropTypes from 'prop-types';
import styles from './tab.module.scss';

const Tab = (props) => {

  return (
    <div className={`${styles.container} ${!props.isActive ? styles.hidden : ''}`}>{props.children}</div>
  );
};

export default Tab;

Tab.defaultProps = {
  isActive: false,
};

Tab.propTypes = {
  isActive: PropTypes.bool,
  children: PropTypes.node,
};
