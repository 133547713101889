import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import ActionLink from "@components/ui/action-link";
import DesktopNewsletter from "@components/ui/desktop-newsletter";
import EmptySpace from "@components/ui/empty-space";
import Hero from "@components/ui/hero";
import Layout from "@components/domain/shared/layout";
import Accordion from "@components/ui/accordion";
import Spacing from "@components/ui/spacing";
import Sponsors from "@components/ui/sponsors";
import Tabs from "@components/ui/desktop-tabs";
import Title from "@components/ui/title";
import SEO from "@components/ui/seo";
import SectionHeroContent from "@components/domain/homepage/hero-content";
import SectionOpenCourses from "@components/domain/homepage/open-courses";
import SectionClosedCourses from "@components/domain/homepage/closed-courses";
import AlunoDeCostas from "@images/domain/homepage/aluno-de-costas.jpg";
import Champalimaud from "@images/domain/homepage/champalimaud.jpg";
import HomeStudy from "@images/domain/homepage/home-study.jpg";
import Ist from "@images/domain/homepage/ist.jpg";
import IstExterior7 from "@images/domain/homepage/ist-exterior-7-copy.jpg";
import styles from "@styles/pages/index.module.scss";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query HeroBackgroundImageQuery {
      backgroundImage: file(name: { eq: "homepage-hero" }) {
        name
        internal {
          mediaType
        }
        childImageSharp {
          fluid(quality: 100, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          fixed(width: 1260) {
            height
            width
            src
          }
        }
      }
    }
  `);

  return (
    <React.Fragment>
      <Hero heroImage={data.backgroundImage.childImageSharp.fluid}>
        <SectionHeroContent />
      </Hero>

      <Layout sideNote="scroll">
        <SEO
          description="afterschool. Cursos extracurriculares de excelência em ciência, tecnologia, engenharia e matemática."
          image={{
            src: data.backgroundImage.childImageSharp.fixed.src,
            height: data.backgroundImage.childImageSharp.fixed.height,
            width: data.backgroundImage.childImageSharp.fixed.width,
            name: data.backgroundImage.name,
            mediaType: data.backgroundImage.internal.mediaType,
          }}
        />

        <Spacing>
          <EmptySpace desktop={{ margin: 140 }} mobile={{ margin: 40 }} />
        </Spacing>

        <div className={styles.sectionLearnMore}>
          <div className={styles.media}>
            <img src={AlunoDeCostas} alt="aluno de costas" />
            <div className={styles.yellowFragment}></div>
          </div>
          <div className={styles.content}>
            <Title value="Aprende mais!" />
            <section className={styles.description}>
              <p>
                O After School funciona num regime de aula invertida (flipped
                classroom). Isto significa que, em casa, os alunos leem, pensam
                e assistem a vídeos com os conteúdos programáticos. Nas aulas,
                resolvem exercícios e esclarecem dúvidas com os nossos
                instrutores.
              </p>
              <p>
                No After School, o acompanhamento da aprendizagem e a seleção
                dos conteúdos são feitos por professores, engenheiros,
                investigadores e alunos de excelência do ensino nacional.
              </p>
            </section>
            <div className={styles.action}>
              <ActionLink path="/cursos" label="sabe como" />
            </div>
          </div>
        </div>

        <Spacing>
          <EmptySpace
            desktop={{ margin: 210 }}
            mobile={{ margin: 160, isLineVisible: true }}
          />
        </Spacing>

        <SectionOpenCourses />

        <Spacing>
          <EmptySpace desktop={{ margin: 140 }} mobile={{ margin: 80 }} />
        </Spacing>

        <SectionClosedCourses />

        <Spacing>
          <EmptySpace desktop={{ margin: 140 }} mobile={{ isHidden: true }} />
        </Spacing>

        <DesktopNewsletter />

        <Spacing>
          <EmptySpace desktop={{ margin: 120 }} mobile={{ margin: 80 }} />

          <Title value="Onde se aprende" />

          <div className={styles.whereToLearnDesktopSection}>
            <Tabs>
              <div label="Instituto Superior Técnico">
                <div className={styles.description}>
                  <p>
                    O Instituto Superior Técnico é uma das maiores escolas de
                    arquitetura, engenharia, ciência e tecnologia em Portugal.
                    Com uma comunidade de mais de 12000 pessoas, o Técnico
                    pretende contribuir para o desenvolvimento da sociedade,
                    promovendo um Ensino Superior de excelência. Sendo um dos
                    maiores parceiros do TreeTree2, o Instituto Superior Técnico
                    tem sido o palco de vários cursos e atividades.
                  </p>
                </div>
                <img
                  className={styles.thumbnail}
                  src={IstExterior7}
                  alt="ist"
                />
              </div>
              <div label="Centro Champalimaud">
                <div className={styles.description}>
                  <p>
                    O Centro Champalimaud, pertencente à Fundação Champalimaud
                    em Lisboa, desenvolve a sua atividade nas áreas das
                    neurociências e do cancro, através de programas de
                    investigação e da prestação de serviços clínicos de
                    excelência, levando também a cabo, fora de portas, um
                    programa de luta contra a cegueira. Foi no Centro
                    Champalimaud, com vista para o Tejo, que decorreu a primeira
                    edição do After School de Neurociência.
                  </p>
                </div>
                <img
                  className={styles.thumbnail}
                  src={Champalimaud}
                  alt="ist"
                />
              </div>
              <div label="Em tua casa">
                <div className={styles.description}>
                  <p>
                    Sabias que a tua casa é o melhor sítio para aprender? Num
                    quarto, com acesso à internet e com a companhia de colegas
                    de Portugal inteiro, ninguém te pode impedir de aprenderes o
                    que quiseres! A versão online dos cursos After School chega
                    a todo o país e dá-te a possibilidade de descobrires os
                    recursos e a comunidade que te vão permitir explorar o teu
                    talento e curiosidade para a ciência, tecnologia, engenharia
                    e matemática.
                  </p>
                </div>
                <img className={styles.thumbnail} src={HomeStudy} alt="ist" />
              </div>
            </Tabs>
          </div>
        </Spacing>

        <div className={styles.whereToLearnMobileSection}>
          <Accordion>
            <div label="Instituto Superior Técnico">
              <div className={styles.description}>
                <p>
                  O Instituto Superior Técnico é uma das maiores escolas de
                  arquitetura, engenharia, ciência e tecnologia em Portugal. Com
                  uma comunidade de mais de 12000 pessoas, o Técnico pretende
                  contribuir para o desenvolvimento da sociedade, promovendo um
                  Ensino Superior de excelência. Sendo um dos maiores parceiros
                  do TreeTree2, o Instituto Superior Técnico tem sido o palco de
                  vários cursos e atividades.
                </p>
              </div>
              <img className={styles.thumbnail} src={Ist} alt="ist" />
            </div>

            <div label="Centro Champalimaud">
              <div className={styles.description}>
                <p>
                  O Centro Champalimaud, pertencente à Fundação Champalimaud em
                  Lisboa, desenvolve a sua atividade nas áreas das neurociências
                  e do cancro, através de programas de investigação e da
                  prestação de serviços clínicos de excelência, levando também a
                  cabo, fora de portas, um programa de luta contra a cegueira.
                  Foi no Centro Champalimaud, com vista para o Tejo, que
                  decorreu a primeira edição do After School de Neurociência.
                </p>
              </div>
              <img
                className={styles.thumbnail}
                src={Champalimaud}
                alt="Champalimaud"
              />
            </div>

            <div label="Em tua casa">
              <div className={styles.description}>
                <p>
                  Sabias que a tua casa é o melhor sítio para aprender? Num
                  quarto, com acesso à internet e com a companhia de colegas de
                  Portugal inteiro, ninguém te pode impedir de aprenderes o que
                  quiseres! A versão online dos cursos After School chega a todo
                  o país e dá-te a possibilidade de descobrires os recursos e a
                  comunidade que te vão permitir explorar o teu talento e
                  curiosidade para a ciência, tecnologia, engenharia e
                  matemática.
                </p>
              </div>
              <img
                className={styles.thumbnail}
                src={HomeStudy}
                alt="em tua casa"
              />
            </div>
          </Accordion>
        </div>

        <Spacing>
          <EmptySpace desktop={{ margin: 40 }} mobile={{ isHidden: true }} />
          <EmptySpace
            desktop={{ margin: 160, isLineVisible: true }}
            mobile={{ margin: 120, isLineVisible: true }}
          />
          <Sponsors />
          <EmptySpace desktop={{ margin: 120 }} mobile={{ margin: 80 }} />
        </Spacing>
      </Layout>
    </React.Fragment>
  );
};

export default IndexPage;
