import React from 'react';
import styles from './hero-content.module.scss';
import Contacts from '@components/ui/contacts';
import Title from '@components/ui/title';
import ActionLink from '@components/ui/action-link';
import { useStaticQuery,  graphql } from "gatsby";

const IndexHeroContent = () => {
  const data = useStaticQuery(graphql`
    query LogosQuery {
      logos: allFile(filter: {extension: {eq: "svg"}, relativeDirectory: {eq: "domain/homepage/logos"}}) {
        nodes {
          name
          publicURL
        }
      }
    }
  `);

  return (
    <div className={styles.container}>
            
      <div className={styles.top}>
        <Title value="Vai além da escola!" />
        <p className={styles.description}>conhece pessoas como tu e percebe que hoje já não há limites para a tua curiosidade.</p>
        <ActionLink path="candidatura" label="sabe como" width="160px" type="dark" />
      </div>

      <div className={styles.bottom}>
        <div className={styles.logos}>
          {data.logos.nodes.map(logo => (
            <img
              className={styles.logo}
              key={logo.name}
              src={logo.publicURL}
              alt={logo.name}
            />
          ))}
        </div>
        <Contacts isOneLine={true} />
      </div>

    </div>
  );
};

export default IndexHeroContent;
